var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { centered: "", width: 500, title: "禁言" },
      on: { ok: _vm.handleConfirm, cancel: _vm.handleCancel },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "a-form-model",
            _vm._b(
              { attrs: { model: _vm.forms } },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "禁言时间", prop: "timeSecond" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        closable: "",
                        "filter-option": _vm.untils.filterOption,
                        showSearch: "",
                        placeholder: "选择拉黑的时长"
                      },
                      model: {
                        value: _vm.forms.timeSecond,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "timeSecond", $$v)
                        },
                        expression: "forms.timeSecond"
                      }
                    },
                    _vm._l(_vm.blackLinkTimeList, function(item, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: item.value } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }