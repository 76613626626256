<template>
  <a-drawer
      title="提现明细"
      placement="right"
      :width="'60vw'"
      :closable="true"
      :visible="visible"
      @close="onClose"
  >
    <div class="price-box mb-10">
      <span class="color-blue font-size-20 font-weight-bold">斗泉钱包余额：￥{{ dqPrice || 0.00 }}</span>
      <span class="color-green font-size-20 font-weight-bold ml-40">连连钱包余额：￥{{ lianLianPrice || 0.00 }}</span>
      <span class="color-red font-size-20 font-weight-bold ml-40">钱包总余额：￥{{ walletTotalPrice }}</span>
    </div>
    <div style="height: 80vh; overflow-y: scroll">
      <a-table
          @change="changePage"
          :pagination="pagination"
          :loading="loading"
          :columns="columns"
          :data-source="list"
          :rowKey="(record, index) => index"
      >
        <div slot="itemIdOrTimeSlot" slot-scope="row">
          <template v-if="row.type === 'income' && row.isAfterWithdrawal">
            <div class="color-green" v-if="row.id">{{ row.id }}</div>
            <div class="mt-10 color-green">{{ row.createTime }}</div>
          </template>
          <template v-else-if="row.type === 'income' && row.color">
            <div class="color-orange" v-if="row.id">{{ row.id }}</div>
            <div class="mt-10 color-orange">{{ row.createTime }}</div>
          </template>
          <template v-else>
            <div class="color-blue" v-if="row.id">{{ row.id }}</div>
            <div class="mt-10 color-gray">{{ row.createTime }}</div>
          </template>
        </div>
        <div slot="orderDetailSlot" slot-scope="row">
          <span v-if="row.type === 'income' && row.isAfterWithdrawal" style="color: green;cursor: pointer;" @click="handleGoToOrderDetail(row.orderNo)">{{ row.orderNo }}</span>
          <span v-else-if="row.type === 'income' && row.color" style="color: orange;cursor: pointer;" @click="handleGoToOrderDetail(row.orderNo)">{{ row.orderNo }}</span>
          <span v-else style="color: #2f54eb;cursor: pointer;" @click="handleGoToOrderDetail(row.orderNo)">{{ row.orderNo }}</span>
        </div>
        <!--    提现，剩余等金额    -->
        <div slot="itemTypeSlot" slot-scope="row">
          <div v-if="row.type === 'income' && row.isAfterWithdrawal"><span style="color:green">{{row.type}}</span></div>
          <div v-else-if="row.type === 'income' && row.color"><span style="color:orange">{{row.type}}</span></div>
          <div v-else><span style="color:red">{{row.type}}</span></div>
        </div>
      </a-table>
    </div>
  </a-drawer>
</template>

<script>
import {convertStringToTimestamp} from "@/untils/timeAbout"

export default {
  data() {
    return {
      lianLianPrice: 0,
      dqPrice: 0,
      walletTotalPrice: 0,
      titleText: '',
      visible: false,
      list: [],
      loading: false,
      params: {
        userId: '',
        pageNum: 1,
        pageSize: 30,
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 30,
        showTotal: (total) => `共${total}条`,
      },
      columns: [
        {
          align: "center",
          key: "time",
          title: "交易时间",
          width: 120,
          scopedSlots: { customRender: 'itemIdOrTimeSlot'}
        },
        {
          align: "center",
          title: `应收金额`,
          customRender: (text, row, index) => {
            return row.type === 'income' && row.isAfterWithdrawal ? <span style="color:green">￥{row.money}</span> :
                row.type === 'income' && row.color ? <span style="color:orange">￥{row.money}</span> :
                    row.type === 'payout' ? <span style="color:red">-￥{row.money}</span> :
                        <span style="color:red">￥{row.money}</span>
          },
        },
        {
          align: "center",
          title: `备注`,
          customRender: (text, row, index) => {
            return row.type === 'income' && row.isAfterWithdrawal ? <span style="color:green">{row.remarks}</span> :
                row.type === 'income' && row.color ? <span style="color:red">{row.remarks}</span> : <span style="color:red">{row.remarks}</span>;
          },
        },
        {
          align: "center",
          title: "类型",
          scopedSlots: { customRender: 'itemTypeSlot'}
        },
        {
          align: "center",
          title: "查看订单",
          scopedSlots: { customRender: "orderDetailSlot" }
        }
      ],
    };
  },
  created() {
  },
  methods: {
    /** 去往订单详情 */
    handleGoToOrderDetail(orderNo) {
      const origin = location.origin
      const url = `${origin}/#/cms/dqplatform/ordermannage?orderNo=${orderNo}`;
      window.open(url)
    },
    async getList() {
      this.loading = true;
      const res = await this.axios("/dq_admin/userAccount/getAccountDetail", {
        params: this.params
      });
      this.loading = false;
      if (res.status !== '200') return (this.list = []);
      this.list = res.data.records
      this.pagination.total = res.data.total;
    },
    // 切换分页
    changePage(page) {
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.params.pageNum = current;
      this.params.pageSize = pageSize;
      this.getList();
    },
    async show(row) {
      this.params.pageNum = 1
      this.params.userId = row.userId
      this.dqPrice = row.availableMoney || 0.00
      this.lianLianPrice = row.lianLianMoney || 0.00
      this.walletTotalPrice = (this.dqPrice * 100 + this.lianLianPrice * 100) / 100
      await this.getList()
      this.visible = true
    },
    onClose() {
      this.pagination.current = 1
      this.visible = false;
      setTimeout(() => {
        this.$emit("close");
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    height: 60px !important;
  }
}
.total-price {
  width: 100%;
  text-align: left;
  position: absolute;
  top: 10px;
  left: 300px;
  font-weight: bold;
  color: orangered;
  font-size: 20px;

}
.ml-40 {
  margin-left: 40px;
}
</style>