<template>
  <a-modal
      centered
      :width='500'
      title="禁言"
      v-model="showModal"
      @ok='handleConfirm'
      @cancel='handleCancel'
  >
    <div>
      <a-form-model v-bind="layout" :model='forms'>
        <a-form-model-item label='禁言时间' prop='timeSecond'>
          <a-select
              v-model="forms.timeSecond"
              closable
              :filter-option="untils.filterOption"
              showSearch
              placeholder="选择拉黑的时长"
          >
            <a-select-option
                v-for="(item, index) of blackLinkTimeList"
                :key="index"
                :value="item.value"
            >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>
<script>
import untils from "@/untils"
import {BLACK_LINK_TIME_LIST} from "@/views/cmsPage/dqPlatform/_data"
import {userBanSpeakApi} from "@/views/cmsPage/dqPlatform/_apis"
export default {
  data() {
    return {
      untils,
      showModal: false,
      blackLinkTimeList: BLACK_LINK_TIME_LIST,
      forms: {
        userId: undefined,
        timeSecond: 31536000,
      },
      layout:{
        labelCol: { span: 4 },//标签占5份  共24份
        wrapperCol: { span:20 },
      }
    }
  },
  methods: {
    async show(userId) {
      this.forms.userId = userId
      this.showModal = true
    },
    /** 确定 */
    async handleConfirm() {
      if (!this.forms.timeSecond) return this.$message.warn('请选择禁言时长')
      this.$loading.show()
      const res = await userBanSpeakApi(this.forms)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info(res.message)
      this.$emit('addSuccess')
      this.handleCancel()
    },
    /** 重置表单 */
    handleCancel() {
      this.forms = {
        userId: undefined,
        timeSecond: 31536000,
      }
      this.showModal = false
    },
  }
}
</script>
<style scoped lang="scss">

</style>